import { setLightness, transparentize } from 'polished';
import { createGlobalStyle } from 'styled-components';

import { rsuiteColors } from './rsuite';

const globalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: none;
        border: none;
        box-sizing: border-box;
        /* font-family: 'Inter', Arial, sans-serif; */
        font-family: 'Open Sans', sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

        -webkit-tap-highlight-color: transparent;
    }

    :root {
        --color-primary: ${({ theme }) => theme.colors.primary};
        --color-secondary: ${({ theme }) => theme.colors.secondary};

        --color-surface: ${({ theme }) => theme.colors.surface};

        --color-title: ${({ theme }) => theme.colors.title};
        --color-text: ${({ theme }) => theme.colors.text};

        --color-background: ${({ theme }) => theme.colors.background};
        --color-line: ${({ theme }) => theme.colors.line};
        --color-header: ${({ theme }) => theme.colors.header};
        --color-red-1: #e83f5b;
        --color-red-2: #ce4a4a;
        --color-red-3: #eb3223;

        --color-green-1: #00875f;
        --color-green-2: #00b37e;
        --color-green-3: #015f43;
        --color-green-4: #00291d;
        --color-green-5: #00875f;
        --color-green-6: #04d361;


        ${(props) => rsuiteColors(props.theme)}

        --gradient: linear-gradient(
            60deg,
            #7297c5,
            #94b2c8 35%,
            #f6b0c7 70%,
            #f9ded7 95%
        );

        /* --gradient: linear-gradient(
  45deg,
  #7297c5,
  #8ea9c7 30%,
  #ffae9b 65%,
  #ffdeba 95%
); */


        --gradient: linear-gradient(
            45deg,
            #7297c5,
            #8fb8e6 40%,
            #a0daf7 75%,
            #c3f1ff 95%
        );

        /* --gradient: #fff; */


        /* Fonts */
        --text-size: 1rem;
        --title-size: 2rem;

        /* Sizes */
        --border-radius: .25rem;

        --swiper-theme-color: var(--color-primary) !important;
    }

    ::-webkit-scrollbar {
        width: 4px;
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--color-primary);
    }

    ::selection {
        background: ${(props) => transparentize(0.9, props.theme.colors.title)};
    }

    .content {
        width: 1120px;
        width: 1216px;
        margin: 0 auto;
    }

    html {
        scroll-behavior: smooth;
    }

    body.active {
        overflow: hidden;
        font-size: var(--text-size);
    }

    html, body, #root {
        position: relative;
        min-height: 100vh;
        color: ${({ theme }) => theme.colors.text};
        background: ${({ theme }) => theme.colors.background};
        -webkit-font-smoothing: antialiased;
    }

    h1, h2, h3, h4, h5, h6 {
        color: ${({ theme }) => theme.colors.title};
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    button {
        cursor: pointer;
    }

    img {
        user-select: none;
        pointer-events: none;
    }

    i {
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
    }

    .shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
    }

    .react-loading-skeleton {
        --base-color: ${(props) => (props.theme.name === 'light' ? '#ebebeb' : setLightness(0.1, '#ebebeb'))} !important;
        --highlight-color: ${(props) => (props.theme.name === 'light' ? '#f5f5f5' : setLightness(0.125, '#f5f5f5'))} !important;
    }

    .gradient-text {
        /* Scale */
        background: linear-gradient(50deg, #714dff, #9c83ff 31.28%, #e151ff 77.97%, #fff759 95.64%);

        /* Gradient 1 */
        background: hsla(175, 79%, 63%, 1);
        background: linear-gradient(90deg, hsla(175, 79%, 63%, 1) 0%, hsla(82, 96%, 57%, 1) 100%);
        background: #fff;



        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .lucide {
        width: 1em;
        height: 1em;
    }

    .rs-picker-daterange-header.rs-picker-tab-active-end::after, .rs-picker-daterange-header.rs-picker-tab-active-start::after {
        border-color: var(--color-secondary);
    }

    .rs-picker-popup {
        border: 1px solid ${(props) =>
			transparentize(0.1, props.theme.colors.line)};
        z-index: 100;
    }

    .rs-picker-popup-daterange {
        .rs-picker-daterange-header::after {
            border-color: var(--color-primary) !important;
        }

        .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
            span {
                color: var(--color-background) !important;
            }
        }
    }

    .rs-btn-close {
        svg {
            stroke: var(--color-text);
            stroke-width: .5px;
        }
    }

    .rs-drawer {
        .rs-drawer-header {
            border-color: ${(props) =>
				transparentize(0.1, props.theme.colors.line)} !important;
        }

        &.rs-drawer-right {
            border-left: 1px solid ${(props) =>
				transparentize(0.1, props.theme.colors.line)};
        }
    }

    .rs-picker-value-count {
        border: 1px solid ${(props) =>
			transparentize(0.1, props.theme.colors.line)};
        padding: 0 7px !important;
    }

    .tippy-box[data-theme~='blur'] {
        background-color: rgba(0, 0, 0, .5);
        backdrop-filter: blur(10px);
        border: 1px solid var(--color-line);

    }

    strong.title {
        margin-bottom: 20px !important;
        padding-bottom: 20px !important;
    }

    @media (max-width: 1024px) {
        .content {
            width: 91%;
        }
    }

    @media (max-width: 834px) {
        .content {
            width: 91%;
        }
    }

    @media (max-width: 768px) {
        .content {
            width: 91%;
        }
    }

    @media (max-width: 540px) {
        .content {
            width: 89%;
        }
    }
`;

export const GlobalStyle = globalStyle as unknown as (
	props: Record<string, never>,
) => JSX.Element;
